'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_1']} flex-col`}>
          <div className={`${styles['box_24']} flex-row justify-between`}>
            <div className={`${styles['box_3']} flex-col`} />
            <div className={`${styles['image-wrapper_1']} flex-col`}>
              <img
                className={`${styles['thumbnail_1']}`}
                src={
                  require('./assets/img/SketchPngc0d98dc63750dc19998877edf286d70c65723838004807c2b4eed4cf64281160.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['text-wrapper_17']} flex-row`}>
            <span className={`${styles['paragraph_1']}`}>
              践行初心
              <br />
              公益法律在行动
            </span>
            <span className={`${styles['paragraph_2']}`}>
              践行初心
              <br />
              公益法律在行动
            </span>
          </div>
        </div>
        <div className={`${styles['group_14']} flex-col`}>
          <div className={`${styles['block_1']} flex-row`}>
            <span className={`${styles['text_4']}`}>已帮助群众</span>
            <span className={`${styles['text_5']}`}>1500+人</span>
          </div>
          <div className={`${styles['group_15']} flex-row justify-between`}>
            <div className={`${styles['box_25']} flex-col justify-between`}>
              <div className={`${styles['text-group_26']} flex-col justify-between`}>
                <span className={`${styles['text_13']}`}>坚守法律公益属性、人民属性、为群众办实事</span>
                <span className={`${styles['text_14']}`}>维全帮，帮助您维护合法权益</span>
              </div>
              <div className={`${styles['grid_5']} flex-row`}>
                <div className={`${styles['list-items_1-0']} flex-col`}>
                  <div className={`${styles['image-text_40-0']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-0']}`}
                      src={
                        require('./assets/img/SketchPng08dfab15f80dd2ac2835ae29b7a671f881ad27be00e667130f281e18be30d4d8.png')
                      }
                    />
                    <span className={`${styles['text-group_2-0']}`}>自己打官司</span>
                  </div>
                  <span className={`${styles['text_15-0']}`}>免费提供起诉书、证据目录、诉讼指引</span>
                </div>
                <div className={`${styles['list-items_1-1']} flex-col`}>
                  <div className={`${styles['image-text_40-1']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-1']}`}
                      src={
                        require('./assets/img/SketchPng29ca5b96bf7bd1dbb26685bf2a4bbb1f4392ef60f4d838dd1a2d6d951bbbc7ca.png')
                      }
                    />
                    <span className={`${styles['text-group_2-1']}`}>律师来帮忙</span>
                  </div>
                  <span className={`${styles['text_15-1']}`}>委托律师代理案件</span>
                </div>
                <div className={`${styles['list-items_1-2']} flex-col`}>
                  <div className={`${styles['image-text_40-2']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-2']}`}
                      src={
                        require('./assets/img/SketchPng6472638b948d4742f1301703b3f98e9df7b19c0b3befd22d2e920e6fb84adbea.png')
                      }
                    />
                    <span className={`${styles['text-group_2-2']}`}>律师函</span>
                  </div>
                  <span className={`${styles['text_15-2']}`}>代发律师函</span>
                </div>
                <div className={`${styles['list-items_1-3']} flex-col`}>
                  <div className={`${styles['image-text_40-3']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-3']}`}
                      src={
                        require('./assets/img/SketchPnga5c635ea13d6618edcbd28e2df4f09f437bacdf05b2d68ab3870e6a454450ab4.png')
                      }
                    />
                    <span className={`${styles['text-group_2-3']}`}>咨询</span>
                  </div>
                  <span className={`${styles['text_15-3']}`}>对生活或经营中遇到的法律问题提供咨询服务</span>
                </div>
                <div className={`${styles['list-items_1-4']} flex-col`}>
                  <div className={`${styles['image-text_40-4']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-4']}`}
                      src={
                        require('./assets/img/SketchPng686bdf4e6a77e5917033f0b1e0930ed381192a279abe7aebeb29f19d79e8c021.png')
                      }
                    />
                    <span className={`${styles['text-group_2-4']}`}>合同服务</span>
                  </div>
                  <span className={`${styles['text_15-4']}`}>拟定、审核、修改各类合同</span>
                </div>
                <div className={`${styles['list-items_1-5']} flex-col`}>
                  <div className={`${styles['image-text_40-5']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-5']}`}
                      src={
                        require('./assets/img/SketchPng43dd90f84af30f04d84a7cf32b8740ed5ff1faa08733613c97c2d3081926935e.png')
                      }
                    />
                    <span className={`${styles['text-group_2-5']}`}>常年法律顾问</span>
                  </div>
                  <span className={`${styles['text_15-5']}`}>咨询、合同拟定、修改审核，律师函代发</span>
                </div>
                <div className={`${styles['list-items_1-6']} flex-col`}>
                  <div className={`${styles['image-text_40-6']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-6']}`}
                      src={
                        require('./assets/img/SketchPng43673fd025766340791103a6227f2397370c961e4476f12d0d5a5228ad36429b.png')
                      }
                    />
                    <span className={`${styles['text-group_2-6']}`}>法律文书模板</span>
                  </div>
                  <span className={`${styles['text_15-6']}`}>免费下载百份合同范本</span>
                </div>
                <div className={`${styles['list-items_1-7']} flex-col`}>
                  <div className={`${styles['image-text_40-7']} flex-col justify-between`}>
                    <img
                      className={`${styles['label_2-7']}`}
                      src={
                        require('./assets/img/SketchPng5e082467e0d851373077b1c1fec3cc058d6f2bfa7f8e1e2573fdfd8e6e364654.png')
                      }
                    />
                    <span className={`${styles['text-group_2-7']}`}>诉讼费计算器</span>
                  </div>
                  <span className={`${styles['text_15-7']}`}>免费计算诉讼成本</span>
                </div>
              </div>
            </div>
            <div className={`${styles['box_16']} flex-col`}>
              <img
                className={`${styles['image_12']}`}
                src={
                  require('./assets/img/SketchPng29ac622eca224d33872a559f6c7a33079e22b452e6db62af195e067af17434e4.png')
                }
              />
              <span className={`${styles['text_29']}`}>立即体验维全帮小程序</span>
            </div>
          </div>
          <div className={`${styles['block_2']} flex-col`}>
            <div className={`${styles['text-group_27']} flex-col justify-between`}>
              <span className={`${styles['text_16']}`}>三大免费公益法律服务</span>
              <span className={`${styles['text_17']}`}>维全帮，帮助您维护合法权益</span>
            </div>
            <div className={`${styles['section_8']} flex-row justify-between`}>
              <div className={`${styles['text-wrapper_5']} flex-col`}>
                <span className={`${styles['text_18']}`}>免费提供常用法律文书模板下载</span>
              </div>
              <div className={`${styles['text-wrapper_6']} flex-col`}>
                <span className={`${styles['text_19']}`}>自己打官司不用请律师，免费提供起诉书、整理证据目录、诉讼流程指引。</span>
              </div>
              <div className={`${styles['text-wrapper_7']} flex-col`}>
                <span className={`${styles['text_20']}`}>争议金额在1万元以内且案件管辖在北京市辖区的合同纠纷，律师免费代理。</span>
              </div>
            </div>
          </div>
          <span className={`${styles['text_21']}`}>金牌服务</span>
          <div className={`${styles['group_16']} flex-row`}>
            <div className={`${styles['group_1']} flex-row`}>
              <div className={`${styles['image-text_41']} flex-col justify-between`}>
                <img
                  className={`${styles['image_1']}`}
                  src={
                    require('./assets/img/SketchPng85bc8b7c5bde030cade733fc4399bf7faa9739ebea3beebe56b1bbbd6431a467.png')
                  }
                />
                <span className={`${styles['text-group_4']}`}>快速匹配</span>
              </div>
            </div>
            <span className={`${styles['text_22']}`}>快速匹配</span>
            <div className={`${styles['group_2']} flex-row`}>
              <div className={`${styles['image-text_42']} flex-col justify-between`}>
                <img
                  className={`${styles['image_2']}`}
                  src={
                    require('./assets/img/SketchPng96e82d4023ec2668ead430846596ede7db2767649062eb731622f5ac98885488.png')
                  }
                />
                <span className={`${styles['text-group_5']}`}>专业服务</span>
              </div>
            </div>
            <div className={`${styles['group_3']} flex-row`}>
              <div className={`${styles['image-text_43']} flex-col justify-between`}>
                <img
                  className={`${styles['image_3']}`}
                  src={
                    require('./assets/img/SketchPng3cbf5ad04a847921455e1cc7ca45af5d6e713b9e992bc105dba8f755b172a77a.png')
                  }
                />
                <span className={`${styles['text-group_6']}`}>低价透明</span>
              </div>
            </div>
          </div>
          <div className={`${styles['block_4']} flex-col`}>
            <span className={`${styles['text_23']}`}>三大保障</span>
            <div className={`${styles['list_5']} flex-row`}>
              <div className={`${styles['image-text_5-0']} flex-row`}>
                <img
                  className={`${styles['image_4-0']}`}
                  src={
                    require('./assets/img/SketchPngb413af81ddc2433b6c0be0deae39362e095367939a4b4dcc945988fa72eec964.png')
                  }
                />
                <span className={`${styles['text-group_7-0']}`}>快速匹配</span>
              </div>
              <div className={`${styles['image-text_5-1']} flex-row`}>
                <img
                  className={`${styles['image_5-1']}`}
                  src={
                    require('./assets/img/SketchPngc9541ecea2c9c74484a681fa080db32b0190a1d70534a353f3b0e607b4e91ab0.png')
                  }
                />
                <span className={`${styles['text-group_7-1']}`}>杜绝虚假承诺</span>
              </div>
              <div className={`${styles['image-text_5-2']} flex-row`}>
                <img
                  className={`${styles['image_6-2']}`}
                  src={
                    require('./assets/img/SketchPng5accee2065024c190559c995b1ebf1b351519072f9d5c3d6012da4bc339eae16.png')
                  }
                />
                <span className={`${styles['text-group_7-2']}`}>收费标准化</span>
              </div>
            </div>
          </div>
          <span className={`${styles['text_24']}`}>收费标准</span>
          <div className={`${styles['group_17']} flex-row justify-between`}>
            <div className={`${styles['group_4']} flex-row`}>
              <div className={`${styles['image-text_44']} flex-col justify-between`}>
                <img
                  className={`${styles['image_7']}`}
                  src={
                    require('./assets/img/SketchPngdca55006aca2e5477c6a3d6ac3f3b86f33422eb53ece65e45694fd15952ef86f.png')
                  }
                />
                <div className={`${styles['text-group_28']} flex-col justify-between`}>
                  <span className={`${styles['text_25']}`}>公益免费项目</span>
                  <span className={`${styles['text_26']}`}>
                    律师免费代理管辖法院在北京的、争议金额在1万元&nbsp;(含)以内的合同纠纷，但交给法院的案件受理费、保全费、执行费由委托人承担。
                  </span>
                </div>
              </div>
            </div>
            <div className={`${styles['group_5']} flex-row`}>
              <div className={`${styles['image-text_45']} flex-col justify-between`}>
                <img
                  className={`${styles['image_8']}`}
                  src={
                    require('./assets/img/SketchPng76d3fc46ef839d9a122159c83d69c524ac7046a317992bf8e7f1f795ae68d454.png')
                  }
                />
                <div className={`${styles['text-group_29']} flex-col justify-between`}>
                  <span className={`${styles['text_27']}`}>收费项目(按争议金额)</span>
                  <span className={`${styles['paragraph_3']}`}>
                    1万元-10万元(含):&nbsp;按争议金额的8%收取律师代理费;
                    <br />
                    10万元-30万元(含):&nbsp;按争议金额的6%收取律师代理费;
                    <br />
                    30万元-100万元(含):&nbsp;按2万元/件收取律师代理费;
                    <br />
                    100万元以上的:&nbsp;按风险代理方式收取律师费，基础代理费3万元+取得的财产收益的5%。
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['block_6']} flex-row`}>
            <div className={`${styles['image-text_46']} flex-col justify-between`}>
              <img
                className={`${styles['image_9']}`}
                src={
                  require('./assets/img/SketchPngfcae8dc904aa7491a95a1f5d2d94011817732c59aa4f5ba0063950487cd6f2cc.png')
                }
              />
              <span className={`${styles['text-group_10']}`}>高效沟通</span>
            </div>
          </div>
        </div>
        <div className={`${styles['box_7']} flex-col`}>
          <div className={`${styles['group_18']} flex-row justify-between`}>
            <div className={`${styles['image-text_47']} flex-col justify-between`}>
              <div className={`${styles['block_9']} flex-row justify-between`}>
                <div className={`${styles['box_9']} flex-col`} />
                <div className={`${styles['image-wrapper_2']} flex-col`}>
                  <img
                    className={`${styles['thumbnail_2']}`}
                    src={
                      require('./assets/img/SketchPngc0d98dc63750dc19998877edf286d70c65723838004807c2b4eed4cf64281160.png')
                    }
                  />
                </div>
              </div>
              <span className={`${styles['text-group_11']}`}>维全帮，帮助您维护合法权益</span>
            </div>
            <div className={`${styles['image-text_48']} flex-col justify-between`}>
              <img
                className={`${styles['image_11']}`}
                src={
                  require('./assets/img/SketchPng672ae8604f75165ee7c54fe84c934242ac65aaaa983e664caf85cac84c952edb.png')
                }
              />
              <span className={`${styles['text-group_13']}`}>维全帮小程序</span>
            </div>
          </div>
          <div className={`${styles['text-wrapper_18']} flex-row`}>
            <span className={`${styles['text_28']}`}>
              Copyright&nbsp;©&nbsp;2021-2024&nbsp;北京律帮帮科技有限公司版权所有&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;京ICP备2022018618号-1
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
