'use strict';

import React, {Component} from 'react';

import styles from './assets/index.module.css';

class DDS_lanhupage_0 extends Component {
    openMiniProgram() {
        window.location.href = "weixin://dl/business/?appid=wx50e4b32e71deb34c&path=pages/index/index";
    }

    render() {
        return (
            <div className={`${styles.page} flex-col`}>
                <div className={`${styles['section_1']} flex-col`}>
                    <div className={`${styles['box_3']} flex-row justify-between`}>
                        <div className={`${styles['box_2']} flex-col`}/>
                        <div className={`${styles['image-wrapper_1']} flex-col`}>
                            <img
                                className={`${styles['thumbnail_1']}`}
                                src={
                                    require('./assets/img/SketchPng5e182121d2c3bdc4d5191c9799641e73191391870fcd991ea9dacf49a2405d0a.png')
                                }
                            />
                        </div>
                    </div>
                    <img
                        className={`${styles['image_1']}`}
                        src={
                            require('./assets/img/SketchPngfd3617fd515b50c677808199bbee2c7422dd6051867305350de5c87b24526353.png')
                        }
                    />
                </div>
                <div className={`${styles['group_4']} flex-col`}>
                    <div className={`${styles['text-wrapper_1']} flex-row justify-between`}>
                        <span className={`${styles['text_1']}`}>已帮助群众</span>
                        <span className={`${styles['text_2']}`}>1500+人</span>
                    </div>
                    <div className={`${styles['text-group_15']} flex-col`}>
            <span className={`${styles['paragraph_1']}`}>
              坚守法律公益属性
              <br/>
              人民属性、为群众办实事
            </span>
                        <span className={`${styles['text_3']}`}>维全帮，帮助您维护合法权益</span>
                    </div>
                    <div className={`${styles['grid_3']} flex-row`}>
                        <div className={`${styles['image-text_1']} flex-col`}>
                            <img
                                className={`${styles['label_1']}`}
                                src={
                                    require('./assets/img/SketchPngc6214896f09d41b9e5cc21273e228049d0690e8528abe58d9fdfb1b5f7131dd1.png')
                                }
                            />
                            <div className={`${styles['text-group_16']} flex-col`}>
                                <span className={`${styles['text_4']}`}>自己打官司</span>
                                <span className={`${styles['text_5']}`}>免费提供起诉书、证据目录、诉讼指引</span>
                            </div>
                        </div>
                        <div className={`${styles['image-text_2']} flex-col`}>
                            <img
                                className={`${styles['label_2']}`}
                                src={
                                    require('./assets/img/SketchPngc7ac07f81b0c35a6d60b0b7dc7de01ef9e0c4a332e5adc0ec34a1b00531e4f0e.png')
                                }
                            />
                            <div className={`${styles['text-group_17']} flex-col`}>
                                <span className={`${styles['text_6']}`}>律师来帮忙</span>
                                <span className={`${styles['text_7']}`}>委托律师代理案件</span>
                            </div>
                        </div>
                        <div className={`${styles['group_1']} flex-col`}>
                            <div className={`${styles['image-text_13']} flex-col`}>
                                <img
                                    className={`${styles['label_3']}`}
                                    src={
                                        require('./assets/img/SketchPng87bdd2dbff16056f19f88894d7744fb399d1c998708be4cf655f8a5ec78685a4.png')
                                    }
                                />
                                <span className={`${styles['text-group_4']}`}>律师函</span>
                            </div>
                            <span className={`${styles['text_8']}`}>代发律师函</span>
                        </div>
                        <div className={`${styles['group_2']} flex-col`}>
                            <img
                                className={`${styles['label_4']}`}
                                src={
                                    require('./assets/img/SketchPng1fcf1ce4f38f26419e9ab718b3cb8c0661ed56cba5dcc4760dcbb10eb07854eb.png')
                                }
                            />
                            <span className={`${styles['text_9']}`}>咨询</span>
                            <span className={`${styles['text_10']}`}>对生活或经营中遇到的法律问题提供咨询服务</span>
                        </div>
                        <div className={`${styles['group_3']} flex-col`}>
                            <div className={`${styles['image-text_14']} flex-col`}>
                                <img
                                    className={`${styles['label_5']}`}
                                    src={
                                        require('./assets/img/SketchPngef8ab4e76ddb2a54e239a6666d84f638c15c68f1d30055f1f2819edcfd5006a4.png')
                                    }
                                />
                                <span className={`${styles['text-group_5']}`}>合同服务</span>
                            </div>
                            <span className={`${styles['text_12']}`}>拟定、审核、修改各类合同</span>
                        </div>
                        <div className={`${styles['image-text_5']} flex-col`}>
                            <img
                                className={`${styles['label_6']}`}
                                src={
                                    require('./assets/img/SketchPngda8827e4dcb112614db4f99a4afc1665cb7de4f218942bbad5b748644e4b5609.png')
                                }
                            />
                            <div className={`${styles['text-group_18']} flex-col`}>
                                <span className={`${styles['text_13']}`}>常年法律顾问</span>
                                <span className={`${styles['text_14']}`}>咨询、合同拟定、修改审核，律师函代发</span>
                            </div>
                        </div>
                        <div className={`${styles['image-text_6']} flex-col`}>
                            <img
                                className={`${styles['label_7']}`}
                                src={
                                    require('./assets/img/SketchPngdd900879ba67f45018976436f1881eca8a0770495e5dce835d08b39a4fce0d04.png')
                                }
                            />
                            <div className={`${styles['text-group_19']} flex-col`}>
                                <span className={`${styles['text_15']}`}>法律文书模板</span>
                                <span className={`${styles['text_16']}`}>免费下载百份合同范本</span>
                            </div>
                        </div>
                        <div className={`${styles['image-text_7']} flex-col`}>
                            <img
                                className={`${styles['label_8']}`}
                                src={
                                    require('./assets/img/SketchPng328495ec121de44dbed5201cb723354629c160ba2ea3093ffe3ba6a364d89853.png')
                                }
                            />
                            <div className={`${styles['text-group_20']} flex-col`}>
                                <span className={`${styles['text_17']}`}>自己打官司</span>
                                <span className={`${styles['text_18']}`}>免费计算诉讼成本</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['section_4']} flex-col`}>
                    <div className={`${styles['text-group_21']} flex-col`}>
                        <span className={`${styles['text_19']}`}>三大免费公益法律服务</span>
                        <span className={`${styles['text_20']}`}>维全帮，帮助您维护合法权益</span>
                    </div>
                    <div className={`${styles['text-wrapper_2']} flex-col`}>
                        <span className={`${styles['text_21']}`}>免费提供常用法律文书模板下载</span>
                    </div>
                    <div className={`${styles['text-wrapper_3']} flex-col`}>
                        <span
                            className={`${styles['text_22']}`}>自己打官司不用请律师，免费提供起诉书、整理证据目录、诉讼流程指引。</span>
                    </div>
                    <div className={`${styles['text-wrapper_4']} flex-col`}>
                        <span
                            className={`${styles['text_23']}`}>争议金额在1万元以内且案件管辖在北京市辖区的合同纠纷，律师免费代理。</span>
                    </div>
                </div>
                <div className={`${styles['group_5']} flex-col`}>
                    <span className={`${styles['text_24']}`}>金牌服务</span>
                    <div className={`${styles['grid_4']} flex-row`}>
                        <div className={`${styles['image-text_8-0']} flex-col`}>
                            <img
                                className={`${styles['image_2-0']}`}
                                src={
                                    require('./assets/img/SketchPngbedb0b573a7d17d8cac2cd4cb3f38d3797b9cf08caeb5b79b05b3ecbe77e475a.png')
                                }
                            />
                            <span className={`${styles['text-group_10-0']}`}>快速匹配</span>
                        </div>
                        <div className={`${styles['image-text_8-1']} flex-col`}>
                            <img
                                className={`${styles['image_2-1']}`}
                                src={
                                    require('./assets/img/SketchPngdba83290cf99611e2d8dbcda0cec93928732554238859c7f454a6796c0c36393.png')
                                }
                            />
                            <span className={`${styles['text-group_10-1']}`}>高效沟通</span>
                        </div>
                        <div className={`${styles['image-text_8-2']} flex-col`}>
                            <img
                                className={`${styles['image_2-2']}`}
                                src={
                                    require('./assets/img/SketchPng2be49fc082acfd4aefdfdda227126931e48665234aeee66c67b870322456b9a7.png')
                                }
                            />
                            <span className={`${styles['text-group_10-2']}`}>专业服务</span>
                        </div>
                        <div className={`${styles['image-text_8-3']} flex-col`}>
                            <img
                                className={`${styles['image_2-3']}`}
                                src={
                                    require('./assets/img/SketchPng10b36063cd27b4d744b9741d61c71d0c7874aafd41456a61526fe3f553537c68.png')
                                }
                            />
                            <span className={`${styles['text-group_10-3']}`}>低价透明</span>
                        </div>
                    </div>
                </div>
                <div className={`${styles['section_6']} flex-col`}>
                    <span className={`${styles['text_25']}`}>三大保障</span>
                    <div className={`${styles['list_2']} flex-col`}>
                        <div className={`${styles['image-text_9-0']} flex-row`}>
                            <img
                                className={`${styles['label_9-0']}`}
                                src={
                                    require('./assets/img/SketchPngbf3d3d6abfee9d0b550c08a55b6fb608c145506649b05cc15e277485f76d57a5.png')
                                }
                            />
                            <span className={`${styles['text-group_11-0']}`}>快速匹配</span>
                        </div>
                        <div className={`${styles['image-text_9-1']} flex-row`}>
                            <img
                                className={`${styles['label_10-1']}`}
                                src={
                                    require('./assets/img/SketchPng7bdad1138fa167c7cf42096d742db7239f48da1d33aa57b58fd39c7c7cf3b0a4.png')
                                }
                            />
                            <span className={`${styles['text-group_11-1']}`}>杜绝虚假承诺</span>
                        </div>
                        <div className={`${styles['image-text_9-2']} flex-row`}>
                            <img
                                className={`${styles['label_9-2']}`}
                                src={
                                    require('./assets/img/SketchPng0186fb7046cb673adc41b9c0ef3e78c549a025f7826e4fbcc43acdc56e051c94.png')
                                }
                            />
                            <span className={`${styles['text-group_11-2']}`}>收费标准化</span>
                        </div>
                    </div>
                </div>
                <div className={`${styles['group_6']} flex-col`}>
                    <span className={`${styles['text_26']}`}>收费标准</span>
                    <div className={`${styles['block_2']} flex-row`}>
                        <div className={`${styles['image-text_15']} flex-col`}>
                            <img
                                className={`${styles['image_3']}`}
                                src={
                                    require('./assets/img/SketchPngdca55006aca2e5477c6a3d6ac3f3b86f33422eb53ece65e45694fd15952ef86f.png')
                                }
                            />
                            <div className={`${styles['text-group_22']} flex-col`}>
                                <span className={`${styles['text_27']}`}>公益免费项目</span>
                                <span className={`${styles['text_28']}`}>
                  律师免费代理管辖法院在北京的、争议金额在1万元&nbsp;(含)以内的合同纠纷，但交给法院的案件受理费、保全费、执行费由委托人承担。
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['section_8']} flex-row`}>
                    <div className={`${styles['image-text_16']} flex-col`}>
                        <img
                            className={`${styles['image_4']}`}
                            src={
                                require('./assets/img/SketchPng76d3fc46ef839d9a122159c83d69c524ac7046a317992bf8e7f1f795ae68d454.png')
                            }
                        />
                        <div className={`${styles['text-group_23']} flex-col`}>
                            <span className={`${styles['text_29']}`}>收费项目(按争议金额)</span>
                            <span className={`${styles['paragraph_2']}`}>
                1万元-10万元(含):&nbsp;按争议金额的8%收取律师代理费;
                <br/>
                10万元-30万元(含):&nbsp;按争议金额的6%收取律师代理费;
                <br/>
                30万元-100万元(含):&nbsp;按2万元/件收取律师代理费;
                <br/>
                100万元以上的:&nbsp;按风险代理方式收取律师费，基础代理费3万元+取得的财产收益的5%。
              </span>
                        </div>
                    </div>
                </div>
                <div className={`${styles['section_9']} flex-col`}>
                    <div className={`${styles['image-text_17']} flex-col`}>
                        <img
                            className={`${styles['image_5']}`}
                            src={
                                require('./assets/img/SketchPng672ae8604f75165ee7c54fe84c934242ac65aaaa983e664caf85cac84c952edb.png')
                            }
                        />
                        <span className={`${styles['text-group_14']}`}>维全帮小程序</span>
                    </div>
                    <span className={`${styles['text_30']}`}>
                        Copyright&nbsp;©&nbsp;2021-2024&nbsp;北京律帮帮科技有限公司版权所有
                        <br/>
                        京ICP备2022018618号-1
                    </span>

                </div>
                <div className={`${styles['section_3']} flex-col`} onClick={this.openMiniProgram}>
                    <div className={`${styles['block_1']} flex-row justify-between`}>
                        <span className={`${styles['text_11']}`}>打开</span>
                        <div className={`${styles['image-wrapper_2']} flex-col`}>
                            <img
                                className={`${styles['thumbnail_2']}`}
                                src={
                                    require('./assets/img/SketchPngb80a422e8c73ed44a31d79408726d514d407c6e24bcb2a3f0f91f4cef9f5bcef.png')
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DDS_lanhupage_0;
