import React, {Component} from 'react'

import LawPC from './view/law_pc/index.jsx'
import LawPhone from './view/law_phone/index.jsx'
import {isMobile} from 'react-device-detect'

class App extends Component {
    render() {
        if (isMobile) {
            return <LawPhone/>;
        }
        return <LawPC/>;
    }
}

export default App
